.card {
  @apply flex flex-col gap-5 !p-4 rounded self-stretch min-w-[256px] max-w-[256px] w-[256px];
}

.used {
	@apply card bg-dark-semi;
}
.used .child {
	@apply bg-white text-dark-gray;
}
.used .title {
	@apply text-dark-light;
}

.live {
	@apply card bg-violet-600;
}
.live .child {
	@apply bg-white text-orange-600;
}
.live .title {
	@apply text-white;
}

.next {
	@apply card bg-violet-400;
}
.next .child {
	@apply bg-violet-600 text-white;
}
.next .title {
	@apply text-white;
}

.notUsed {
	@apply card bg-violet-100;
}
.notUsed .child {
	@apply bg-white text-green-600;
}
.notUsed .title {
	@apply text-dark-mid;
}
