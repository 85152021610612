.wrong {
  @apply !text-white !bg-orange-600;
}

.right {
  @apply !text-white !bg-green-600;
}

.unChecked {
  @apply text-dolphin-700 bg-white;
}
