@tailwind base;
@tailwind components;
@tailwind utilities;

html * {
	font-family: "Fira Sans", system-ui, sans-serif !important;
}

.custom-scroll {
	--sb-track-color: #f5edff;
	--sb-thumb-color: #9747ff;
	--sb-size: 9px;
}

.custom-scroll::-webkit-scrollbar {
	width: var(--sb-size);
	height: 5px;
}

.custom-scroll::-webkit-scrollbar-track {
	background: var(--sb-track-color);
	border-radius: 50px;
}

.custom-scroll::-webkit-scrollbar-thumb {
	background: var(--sb-thumb-color);
	border-radius: 50px;
}

@supports not selector(::-webkit-scrollbar) {
	.custom-scroll {
		scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
	}
}

.btn-base {
	@apply transition-all duration-300;
}

.violet-btn {
	@apply bg-violet-600 text-white hover:bg-violet-700 disabled:bg-violet-400 hover:disabled:bg-violet-500;
}

.orange-btn {
	@apply bg-orange-600 text-white hover:bg-orange-700 disabled:bg-orange-400 hover:disabled:bg-orange-500;
}

.green-btn {
	@apply bg-green-600 text-white hover:bg-green-700 disabled:bg-green-400 hover:disabled:bg-green-500;
}

.cyan-btn {
	@apply bg-cyan-600 text-white hover:bg-cyan-700 disabled:bg-cyan-400 hover:disabled:bg-cyan-500;
}

.denim-btn {
	@apply bg-denim-600 text-white hover:bg-denim-700 disabled:bg-denim-400 hover:disabled:bg-denim-500;
}

.red-btn {
	@apply bg-red-600 text-white hover:bg-red-700 disabled:bg-red-400 hover:disabled:bg-red-500;
}

.blaze-btn {
	@apply bg-blaze-600 text-white hover:bg-blaze-700 disabled:bg-blaze-400 hover:disabled:bg-blaze-500;
}

.bile-btn {
	@apply bg-bile-600 text-white hover:bg-bile-700 disabled:bg-bile-400 hover:disabled:bg-bile-500;
}

.blue-btn {
	@apply bg-blue-600 text-white hover:bg-blue-700 disabled:bg-blue-400 hover:disabled:bg-blue-500;
}

.yellow-btn {
	@apply bg-yellow-600 text-white hover:bg-yellow-700 disabled:bg-yellow-400 hover:disabled:bg-yellow-500;
}

.pink-btn {
	@apply bg-pink-600 text-white hover:bg-pink-700 disabled:bg-pink-400 hover:disabled:bg-pink-500;
}

.outline-btn {
	@apply text-white bg-[#FFFFFF40] hover:bg-[#FFFFFF80] border-[1px] border-white border-solid;
}

.glassmor_it {
	background: rgba(240, 125, 246, 0.25);
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 10px );
	-webkit-backdrop-filter: blur( 10px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
}